:root {
    --primaryColor: #722ed1;
    --primaryColorHover: #531dab; 
    --primaryColorLight: #f3e6ff;
    --primaryColorLightHover: #e6ccff;
}
html{
    overflow-y:  hidden !important;
}
body{
    overflow-y: auto !important;
}
#header-main .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color .3s;
}
  
#header-main .trigger:hover {
    color: #1890ff;
}

#header-main .logo {
    height: 32px;
    background: rgba(255,255,255,.2);
    margin: 16px;
}
.table-clabs .row-clabs{
    background: #f9f6f6;
}
.table-clabs .clickable-row:hover{
    cursor: pointer;
}
.table-clabs .row-gray{
    background: #f9f6f6;
}
.table-clabs .row-blue{
    background: #e6f7ff;
}
.table-clabs .row-orange{
    background: #fff7e6;
}
.table-clabs .row-green{
    background: #f6ffed;
}
.table-clabs .row-red{
    background: #f3c8c5;
}
.row-green .ant-table-row-hover, .row-red .ant-table-row-hover{
    background: none !important;
}
.table-clabs .row-blue:hover, .row-gray:hover, .row-orange:hover, .row-green:hover, .row-red:hover{
    background: none;
}
.table-clabs.onboarding-active .ant-table {
    z-index: 999;
}
.table-clabs--less-padding td{
    padding: 10px 15px !important;
}
.ant-menu-dark .ant-menu-item-selected{
    background-color: #722ed1 !important;
}
.ant-dropdown-menu-item-active{
    background-color: #f9f0ff !important;
}
.ant-menu-vertical .ant-menu-item:after, .ant-menu-vertical-left .ant-menu-item:after, .ant-menu-vertical-right .ant-menu-item:after, .ant-menu-inline .ant-menu-item:after{
    border-right: 3px solid #722ed1;
}
.cl-menu-main-right{
    float: right;
    height: 100%;
    overflow: hidden;
    margin-right: 20px;
}
.drwawer-padding-0 .ant-drawer-body{
    padding: 0px;
}
.ant-card-type-inner .ant-card-extra {
    padding: 12.5px 0 !important;
}
.btn-success{
    background-color: #52c41a !important;
    color: #fff !important;
}
.btn-success:hover, .btn-success:focus, .btn-success:active{
    border-color: #389e0d !important;
}
.btn-warning{
    background-color: #ffec3d !important;
    color: #fff !important;
    border-color: #ffec3d !important;
}
.btn-warning:hover, .btn-warning:focus, .btn-warning:active{
    border-color: #ffec3d !important;
}
.ant-advanced-search-form-inline {
    padding: 10px !important;
    background: #fbfbfb;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    margin-top: 10px;
}
.editable-cell {
    position: relative;
}
.editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 4px 11px;
}
.ant-select:not(.ant-fullcalendar-year-select, .ant-fullcalendar-month-select) {
    width: 100%;
}
.ant-list-item {
    justify-content: inherit !important;
}
.f-right{
    float: right;
}
.ant-col-5 {
    width: 20% !important;
}
.input-right-prefix {
    position: absolute;
    top: 13px;
    right: 0;
    font-size: 16px;
}

.ant-radio-group.ant-radio-group-outline.ant-radio-group-default {
    display: none;
}

.ant-calendar-input-wrap {
    display: none;
}

.ant-table-wrapper.overflow-x-auto .ant-table-body {
    overflow-x: auto !important;
}

.whatsapp-support-button-container {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
    flex: 1;
    gap: 12px;
}

.whatsapp-support-button {
    background-color: #128C7E !important;
    border: none !important;
    font-weight: bold !important;
    display: flex !important;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}

.whatsapp-support-button:hover {
    filter: brightness(90%);
}

.application-version {
    color: #ffffffa6;
    font-size: 9px;
}


.ant-layout-sider-children {
    display: flex;
    flex-direction: column;
}
.form-image-uploader{
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
}

.ant-switch-checked {
    background-color: var(--primaryColor) !important;
    border-color: var(--primaryColor) !important;
}

.selected-task {
    background-color: var(--primaryColorLight) !important;
}
.selected-task:hover {
    background-color: var(--primaryColorLightHover) !important;
}